import { recomendacion } from './recomendacion';
import { recomendaciones } from './recomendaciones';

export class recomendacionesDieta extends recomendacion {
    public id_paciente !: number;
    public id_dieta !: number;
    public id_nutricionista !: number;
    public recomendaciones!:recomendaciones[]

} 
