import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { recomendacionesDieta } from '@/shared/dtos/recomendaciones/recomendacionesDieta';

@Module({
    namespaced: true,
    name: 'recomendacionModule',
    store,
    dynamic: true
})
class recomendacionModule extends VuexModule {
    public recomendacion_dieta: recomendacionesDieta = new recomendacionesDieta();

    @Action({ commit: 'onGetrecomendacion' })
    public async getrecomendaciondieta(id: any) {
        return await ssmHttpService.get(API.recomendacion + '/recomendaciones_dieta/' + id);
    }

    @Mutation
    public onGetrecomendacion(res: recomendacionesDieta) {
        this.recomendacion_dieta = new recomendacionesDieta(res);
    }

}
export default getModule(recomendacionModule);