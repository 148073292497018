




















import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {}
})
export default class Tabs extends Vue {
  public tabs: any = [];
  public daysIndex = [6, 0, 0, 2, 3, 4, 5];
  created() {
    this.tabs = this.$children;
  }

  @Watch("tabs")
  public ss() {
    if (this.tabs.length > 0) {
      this.selectTab(this.tabs[this.daysIndex[new Date().getDay()]]);
    }
  }

  selectTab(selectedTab: any) {
    this.tabs.forEach((tab: any) => {
      tab.isActive = tab.name == selectedTab.name;
    });
  }
}
