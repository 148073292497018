




import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Tab extends Vue {
  @Prop({ required: true }) name!: any;
  @Prop({ default: false }) selected!: any;
  public isActive = false;
  mounted() {
    this.isActive = this.selected;
  }
}
